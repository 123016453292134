import React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TheMenuCard from "../cards/the-menu-card"

/**
 *
 */
class MenuPage extends React.Component {
    /**
     *
     */
    handleTakeMeToMyLocalStoreClick = () => {
        if (typeof window !== 'undefined' && window.dataLayer) {
            window.dataLayer.push({
                event: "click-button-take-me-to-my-local-store"
            });
        }
    };

    /**
     *
     * @returns {XML}
     */
    render() {
        return (
            <Layout>
                <SEO title="Menu"/>

                <div className="container mt-3">
                    <h1 className="text-center">Menu</h1>

                    <a className="btn btn-block btn-primary"
                       href="geo:37.979130,-122.057991?q=Jimmy%20John%27s%20Sandwich%2C%201110%20Concord%20Ave%20%2320%2C%20Concord%2C%20CA%2094520&z=9"
                       target="_blank" rel="noopener noreferrer" onClick={this.handleTakeMeToMyLocalStoreClick}>Take Me
                        to My Local Store</a>

                    <div className="my-3">
                        <TheMenuCard></TheMenuCard>
                    </div>

                    <a className="btn btn-block btn-primary"
                       href="geo:37.979130,-122.057991?q=Jimmy%20John%27s%20Sandwich%2C%201110%20Concord%20Ave%20%2320%2C%20Concord%2C%20CA%2094520&z=9"
                       target="_blank" rel="noopener noreferrer" onClick={this.handleTakeMeToMyLocalStoreClick}>Take Me
                        to My Local Store</a>

                    <div className="text-center mt-5">
                        <Link className="btn btn-secondary" to="/">Back Home</Link>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default MenuPage
